import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import Modal from 'src/components/01_atoms/Modal/Modal'
import { useScreenHeight } from 'src/hooks/useScreenHeight'
import { closeModal } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IModalDataProperties from 'src/structures/Interfaces/IModalDataProperties'

import './externalcontentmodal.scss'

const ExternalContentModal: FC<IModalDataProperties> = ({ data }) => {
    const screenHeight = useScreenHeight()

    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handleClose = useCallback(() => {
        dispatch(closeModal())
    }, [dispatch])

    return (
        <div style={{ maxHeight: `${screenHeight ?? 0.9}px` }}>
            <Modal
                containerClassName='externalcontentmodal-container'
                contentClassName='externalcontentmodal-content'>
                <div className='inner'>
                    <div className='externalcontentmodal-header'>
                        <div className='c-close-modal-header'>
                            <button
                                aria-label={t('general.button.close.label')}
                                onClick={handleClose}
                                className='c-button-close-modal-small'>
                                <FaTimes />
                            </button>
                        </div>
                    </div>

                    <div className='externalframe'>
                        {isNotNullOrUndefined(data?.url) ? (
                            <iframe
                                src={data.url}
                                width='100%'
                                height='100%'
                                title='External Content'
                            />
                        ) : null}
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ExternalContentModal
