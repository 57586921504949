import { addTimeToCurrentDateTime } from '../../constants/helpers'
import TimeUnit from '../../structures/Enums/TimeUnit.enum.ts'
import { REMOVE_METADATA, SET_METADATA } from '../actions/metadataActions'

export default function metadataReducer(state = {}, { type, payload }) {
    switch (type) {
        case SET_METADATA:
            state = {
                id: String(payload.id),
                expiresAt: addTimeToCurrentDateTime(2, TimeUnit.HOURS),
            }

            return state

        case REMOVE_METADATA:
            state = {}
            return state

        default:
            return state
    }
}
