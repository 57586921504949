import { FC, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { BiGift, BiLockAlt } from 'react-icons/bi'
import { HiPlus } from 'react-icons/hi'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import ProgressBar from 'src/components/ProgressBar/ProgressBar'
import { EventContext } from 'src/context/EventContextProvider'
import { LoyaltyContext } from 'src/context/LoyaltyContextProvider'
import useCatalogueQuery from 'src/hooks/useCatalogueQuery'
import { closeAllModals, openModal } from 'src/redux/actions/actionBuilders'
import CheckoutMethod from 'src/structures/Enums/CheckoutMethod.enum'
import ModalType from 'src/structures/Enums/ModalType'
import RoutePath from 'src/structures/Enums/RoutePath.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import ILoyaltyRewardProduct from 'src/structures/Interfaces/ILoyaltyRewardProduct'
import IProduct from 'src/structures/Interfaces/IProduct'

import Picture from '../Picture/Picture'

import './loyaltyrewardproduct.scss'

interface ILoyaltyRewardProductProperties {
    reward: ILoyaltyRewardProduct
}

const LoyaltyRewardProduct: FC<ILoyaltyRewardProductProperties> = ({ reward }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { event } = useContext(EventContext) as any
    const { getProductById } = useCatalogueQuery({ eventId: event?.id })
    const { loyaltyContact, loyaltyProgramConfig } = useContext(LoyaltyContext)

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const rewardCatalogueProduct: IProduct | null = useMemo(() => {
        if (isNotNullOrUndefined(reward?.product)) {
            return getProductById(reward?.product.id)
        }
        return null
    }, [reward?.product, getProductById])

    // routes where rewards can be added to basket
    const eventMatchPath = useMatch(RoutePath.EVENT)
    const eventGroupMatchPath = useMatch(RoutePath.EVENT_GROUP)
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)

    const userPoints = useMemo(
        () => Number.parseInt(loyaltyContact?.loyalty_balance ?? '0', 10),
        [loyaltyContact?.loyalty_balance]
    )

    const isDisabled = userPoints < reward.required_credits

    const isAvailable =
        reward.is_active &&
        isNotNullOrUndefined(reward.product) &&
        reward.product.is_available &&
        isNotNullOrUndefined(rewardCatalogueProduct) &&
        rewardCatalogueProduct.is_available

    const isAvailableAtCurrentEvent = isNotNullOrUndefined(rewardCatalogueProduct)

    const isAddableToBasket =
        event?.is_open === true &&
        event?.checkout_method.id !== CheckoutMethod.MENU_ONLY &&
        (isNotNullOrUndefined(eventMatchPath) ||
            isNotNullOrUndefined(eventGroupMatchPath) ||
            isNotNullOrUndefined(eventGroupEventMatchPath))

    // make the to be added product free when adding to basket
    // add the loyalty_reward type
    const normalizedProduct = useMemo(() => {
        const product = reward.product
        if (isNotNullOrUndefined(product)) {
            product.price = 0
            product.price_int = 0
            product.recommendations = []
            product.loyalty_credits_label = loyaltyProgramConfig?.custom_credit_name ?? 'Points'
            product.loyalty_reward_credits_price = reward.required_credits
            if (isNotNullOrUndefined(product.additions))
                for (const addition of product.additions) {
                    if (isNotNullOrUndefined(addition.values)) {
                        for (const value of addition.values) {
                            value.price = 0
                            value.price_int = 0
                        }
                    }
                }
        }
        return product
    }, [reward, loyaltyProgramConfig])

    const handleClick = useCallback(() => {
        if (
            !isDisabled &&
            isAvailable &&
            isAddableToBasket &&
            isNotNullOrUndefined(normalizedProduct) &&
            isNotNullOrUndefined(event)
        ) {
            dispatch(
                openModal({
                    type: ModalType.ADD_TO_BASKET_MODAL,
                    data: {
                        product: normalizedProduct,
                        eventId: event.id,
                        eventName: event.name,
                        groupId: undefined,
                        menuOnly: false,
                        maxOrderableAmount: 1,
                        isOrderable: true,
                        amount: 1,
                        type: 'loyalty_reward',
                        onConfirm: () => dispatch(closeAllModals()),
                    },
                })
            )
        }
    }, [isAvailable, isDisabled, isAddableToBasket, normalizedProduct])

    if (isNotNullOrUndefined(reward)) {
        return (
            <button
                className='loyalty-reward-product-item'
                type='button'
                disabled={isDisabled}
                onClick={handleClick}>
                <div className='loyalty-reward-product-item-inner'>
                    <div className='loyalty-reward-product-item-image'>
                        {isNotNullOrUndefined(reward.image) ? <Picture src={reward.image.small} /> : <BiGift />}
                    </div>
                    <div className='loyalty-reward-product-item-data'>
                        <p className='loyalty-reward-product-title'>{reward.name}</p>
                        {isDisabled ? (
                            <div className='loyalty-reward-product-progress'>
                                <span>{`${userPoints}/${reward.required_credits}`}</span>
                                <ProgressBar progress={(userPoints / reward.required_credits) * 100} />
                            </div>
                        ) : (
                            <div className='loyalty-reward-product-price'>
                                {isAvailable || !isAddableToBasket ? (
                                    <span>{reward.required_credits}</span>
                                ) : (
                                    <span className='sold-out'>
                                        {isAvailableAtCurrentEvent
                                            ? t('product.sold_out')
                                            : t('product.not_available_here')}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>

                    {isDisabled ? (
                        <div className='loyalty-reward-product-item-control'>
                            <BiLockAlt />
                        </div>
                    ) : isAddableToBasket && isAvailable ? (
                        <div className='loyalty-reward-product-item-control'>
                            <HiPlus />
                        </div>
                    ) : null}
                </div>
            </button>
        )
    }

    return null
}

export default LoyaltyRewardProduct
