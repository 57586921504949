import { BrowserRouter } from 'react-router-dom'

import DrawerMenu from './components/03_organisms/DrawerMenu/DrawerMenu'
// import { ToastContainer } from 'react-toastify'
import ExtendedTracking from './components/ExtendedTracking/ExtendedTracking'
import Header from './components/header/Header'
import OrderCancelManager from './components/OrderCancelManager/OrderCancelManager'
import ScrollToTop from './components/scrolltotop/ScrollToTop'
import Seo from './components/Seo/Seo'
import ForceScrollBlock from './containers/modal/ForceScrollBlock'
import Modals from './containers/modal/Modals'
import ContextProviders from './context/ContextProviders'
import useMetadataHeader from './hooks/useMetadataHeader'
import Routers from './Routes/Routers'

import './style/app.scss'

const App = () => {
    useMetadataHeader()

    return (
        <BrowserRouter
            future={{
                v7_relativeSplatPath: true,
                v7_startTransition: true,
            }}>
            <ContextProviders>
                <ForceScrollBlock />
                <OrderCancelManager />
                <Seo />
                <Header />
                <Routers />
                <Modals />
                <DrawerMenu />
                <ScrollToTop />
                <ExtendedTracking />
            </ContextProviders>
        </BrowserRouter>
    )
}

export default App
