import * as basketActions from './basketActions'
import * as cashlessCardActions from './cashlessCardActions'
import * as cookiesModalActions from './cookiesModalActions'
import * as kioskActions from './kioskActions'
import * as loyaltyActions from './loyaltyActions'
import * as metadataActions from './metadataActions'
import * as modalActions from './modalActions'
import * as ordersActions from './ordersActions'
import * as tableNumberActions from './tableNumberActions'
import * as themeActions from './themeActions'
import * as tokenActions from './tokenActions'

// Basket actions
export const addToBasket = (payload) => ({
    type: basketActions.ADD_PRODUCT,
    payload,
})
export const updateBasket = (payload) => ({
    type: basketActions.UPDATE_BASKET,
    payload,
})

export const removeFromBasket = (payload) => ({
    type: basketActions.REMOVE_FROM_BASKET,
    payload,
})

export const resetBasket = () => ({
    type: basketActions.RESET_BASKET,
})

export const upsellProduct = (payload) => ({
    type: basketActions.UPSELL_PRODUCT,
    payload,
})

export const appendAdditionsToProduct = (payload) => ({
    type: basketActions.APPEND_ADDITIONS_TO_PRODUCT,
    payload,
})

export const removeProduct = (payload) => ({
    type: basketActions.REMOVE_PRODUCT,
    payload,
})

export const openBasket = () => ({
    type: basketActions.OPEN_BASKET,
})

export const closeBasket = () => ({
    type: basketActions.CLOSE_BASKET,
})

export const addTipToBasket = (payload) => ({
    type: basketActions.ADD_TIP,
    payload,
})

// Token actions
export const updateTokens = (payload) => ({
    type: tokenActions.UPDATE_TOKENS,
    payload,
})

// Modal actions
export const openModal = (payload) => ({
    type: modalActions.OPEN_MODAL,
    payload,
})

export const closeModal = (payload) => ({
    type: modalActions.CLOSE_MODAL,
    payload,
})

export const closeAllModals = (payload) => ({
    type: modalActions.CLOSE_ALL_MODALS,
    payload,
})

export const openCookiesModal = () => ({
    type: cookiesModalActions.OPEN_COOKIES,
})

export const closeCookiesModal = () => ({
    type: cookiesModalActions.CLOSE_COOKIES,
})

// Table number actions
export const setStickyTableNumber = (payload) => ({
    type: tableNumberActions.SET_TABLE_NUMBER,
    payload,
})

export const removeStickyTableNumber = (payload) => ({
    type: tableNumberActions.REMOVE_TABLE_NUMBER,
    payload,
})

// Metadata actions
export const setMetadata = (payload) => ({
    type: metadataActions.SET_METADATA,
    payload,
})

export const removeMetadata = (payload) => ({
    type: metadataActions.REMOVE_METADATA,
    payload,
})

// Loyalty actions
export const removeLoyaltyForVenue = (payload) => ({
    type: loyaltyActions.REMOVE_LOYALTY_FOR_VENUE,
    payload,
})

export const setLoyaltyForVenue = (payload) => ({
    type: loyaltyActions.SET_LOYALTY_FOR_VENUE,
    payload,
})

// CashlessCard (e.g. starnet) actions
export const setCashlessCard = (payload) => ({
    type: cashlessCardActions.SET_CASHLESS_CARD,
    payload,
})

export const resetCashlessCard = () => ({
    type: cashlessCardActions.RESET_CASHLESS_CARD,
})

export const removeAllLoyalty = () => ({
    type: loyaltyActions.REMOVE_ALL_LOYALTY,
})

// order actions
export const setPendingOrder = (payload) => ({
    type: ordersActions.SET_PENDING_ORDER,
    payload,
})

export const removePendingOrder = () => ({
    type: ordersActions.REMOVE_PENDING_ORDER,
})

// kiosk
export const setKioskToken = (payload) => ({
    type: kioskActions.KIOSK_UPDATE_TOKEN,
    payload,
})

export const updateKioskMode = (payload) => ({
    type: kioskActions.KIOSK_SET_KIOSK_MODE,
    payload,
})

export const setKioskDevice = (payload) => ({
    type: kioskActions.KIOSK_SET_DEVICE,
    payload,
})

export const resetKioskStore = () => ({
    type: kioskActions.KIOSK_RESET_STORE,
})

// Theming
export const storeTheme = (payload) => ({
    type: themeActions.SET_THEME,
    payload,
})
