import MediaType from 'src/structures/Enums/MediaType.enum'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'
import IMedia from 'src/structures/Interfaces/IMedia'
import ITheme from 'src/structures/Interfaces/ITheme'

import { getBrand } from '../structures/Enums/Brands.enum'
import HeroType from '../structures/Enums/HeroType.enum'

import { hexToRgb } from './helpers'

const root: HTMLElement = document.documentElement

// Define the whitelist of theme properties
const whiteList: Set<string> = new Set(['color', 'backgroundColor', 'textColor', 'categoryTextColor'])

// Type definition for RGB color components
interface IRGBColor {
    r: number
    g: number
    b: number
}

const colorChannels: Array<string> = ['red', 'green', 'blue']

// Function to set color-based CSS variables
const setColorCssVariables = (variableName: string, variableValue: string): void => {
    // Sanitize the value by removing any `#`
    variableValue = variableValue.replace('#', '')

    // Validate that the value is a valid hex color
    if (/^[\da-f]{6}$/i.test(variableValue)) {
        const colorParts: IRGBColor | null = hexToRgb(`#${variableValue}`)
        if (isNotNullOrUndefined(colorParts)) {
            // Adjustments for different color variants
            const adjustments: Record<string, [number, number, number]> = {
                rgb: [0, 0, 0],
                dark: [-15, -15, -15],
                light: [10, 10, 10],
            }

            // Iterate over adjustments to set variant variables
            for (const [variant, adjustment] of Object.entries(adjustments)) {
                const r = Math.max(0, Math.min(255, colorParts.r + adjustment[0]))
                const g = Math.max(0, Math.min(255, colorParts.g + adjustment[1]))
                const b = Math.max(0, Math.min(255, colorParts.b + adjustment[2]))
                root.style.setProperty(`--b-variant-${variableName}-${variant}`, `${r} ${g} ${b}`)
            }

            // Set individual RGB components
            for (const [index, color] of colorChannels.entries()) {
                const value = [colorParts.r, colorParts.g, colorParts.b][index]
                root.style.setProperty(`--b-partial-${variableName}-${color}`, String(value))
            }
        }

        // Set the main color variable
        root.style.setProperty(`--b-${variableName}`, `#${variableValue}`)
    }
}

// Function to set root CSS variables
export const setRootCssVariable = (variableName: string, variableValue: string): void => {
    if (whiteList.has(variableName)) {
        setColorCssVariables(variableName, variableValue)
    } else {
        // Handle other properties
        root.style.setProperty(`--b-${variableName}`, variableValue)
    }
}

// Function to get the default theme
export const getDefaultTheme = (): ITheme => {
    const {
        theme: {
            color: { primary, background },
            logo: { header },
        },
    } = getBrand()

    return {
        color: primary,
        backgroundColor: background,
        textColor: 'FFFFFF',
        categoryTextColor: '353535',
        logo: header,
        heroStyle: HeroType.HERO_TYPE_ORIGINAL,
        translations: null,
    }
}

export const getMediaForTheme = (input: {
    image: string | undefined | null
    video: string | undefined | null
}): IMedia | null => {
    if (isNotNullOrUndefined(input.video) && input.video !== '') {
        return { type: MediaType.VIDEO, url: input.video }
    }

    if (isNotNullOrUndefined(input.image) && input.image !== '') {
        return { type: MediaType.IMAGE, url: input.image }
    }

    return null
}
