import { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import classNames from 'classnames'

import { BrandContext } from '../../context/BrandContextProvider.tsx'
import { KioskContext } from '../../context/KioskContextProvider'

import Language from './components/Language'

import './languageselector.scss'

function LanguageSelector({ onLanguageChange }) {
    const { i18n } = useTranslation()
    const { brand } = useContext(BrandContext)
    const { kioskMode } = useContext(KioskContext)
    const queryClient = useQueryClient()

    const onChangeLanguage = useCallback(async (e) => {
        await i18n.changeLanguage(e)
        onLanguageChange(e)
        await queryClient.invalidateQueries()
    }, [])

    const languages = i18n.options.supportedLngs

    return (
        <div className={classNames('c-language-selector', kioskMode ? 'c-language-selector--grid' : '')}>
            {languages
                .filter((item) => item !== 'cimode' && brand.languages.includes(item))
                .map((lang) => (
                    <Language
                        key={lang}
                        language={{ lang }}
                        onClick={onChangeLanguage}
                    />
                ))}
        </div>
    )
}
export default LanguageSelector
