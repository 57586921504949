import { FC, useContext } from 'react'
import { useMatch } from 'react-router-dom'
import { motion, useScroll, useTransform } from 'framer-motion'
import { BrandContext } from 'src/context/BrandContextProvider'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import RoutePath from '../../../structures/Enums/RoutePath.enum'
import Picture from '../Picture/Picture'

import './headerbrandinglabel.scss'

type IHeaderBrandingLabel = Record<string, null>

const HeaderBrandingLabel: FC<IHeaderBrandingLabel> = () => {
    const scrollRange = [60, 100]
    const { isTaDa, isKinepolisBitesAndBubbles } = useContext(BrandContext)
    const { scrollY } = useScroll()
    const position = useTransform(scrollY, scrollRange, [1, 0])
    const scale = useTransform(scrollY, scrollRange, [1, 0.8])
    const top = useTransform(scrollY, scrollRange, [0, -10])

    const eventMatchPath = useMatch(`${RoutePath.EVENT}/`)
    const eventGroupEventMatchPath = useMatch(`${RoutePath.EVENT_GROUP_EVENT}/*`)

    let branding = null
    if (isTaDa) branding = '/brand/tada/powered_by_tada_white.svg'
    if (isKinepolisBitesAndBubbles) branding = '/brand/kinepolis-bites-and-bubbles/powered_by_kinepolis_white.svg'

    if (
        isNotNullOrUndefined(branding) &&
        (isNotNullOrUndefined(eventMatchPath) || isNotNullOrUndefined(eventGroupEventMatchPath))
    ) {
        return (
            <motion.div
                className='c-header-branding-label'
                style={{ opacity: position, scale, y: top }}>
                <div className='c-header-branding-label-inner'>
                    <Picture src={branding} />
                </div>
            </motion.div>
        )
    }

    return null
}

export default HeaderBrandingLabel
