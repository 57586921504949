import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { removeMetadata } from 'src/redux/actions/actionBuilders'
import { isNotNullOrUndefined } from 'src/structures/Guards/guards.utils'

import BillySDK from '../sdk/sdk'

const useMetadataHeader = () => {
    const dispatch = useDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metadata = useSelector((state: any) => state.metadata)

    useEffect(() => {
        if (isNotNullOrUndefined(metadata)) {
            const { id, expiresAt } = metadata
            const axiosInstance = BillySDK.getAxiosInstance()

            if (isNotNullOrUndefined(axiosInstance) && isNotNullOrUndefined(id)) {
                axiosInstance.defaults.headers.common = {
                    ...axiosInstance.defaults.headers.common,
                    'X-Personalisation': id,
                }
            }

            if (isNotNullOrUndefined(expiresAt)) {
                const expiryTime = new Date(expiresAt).getTime()
                const currentTime = Date.now()

                if (expiryTime <= currentTime) {
                    // Expired: Remove metadata from Redux and clear the header
                    dispatch(removeMetadata())

                    if (isNotNullOrUndefined(axiosInstance)) {
                        delete axiosInstance.defaults.headers.common['X-Personalisation']
                    }
                }
            }
        }
    }, [metadata, dispatch])
}

export default useMetadataHeader
