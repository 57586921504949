import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import filterExpiredBasketsMiddleware from './middleware/FilterExpiredBasketsMiddleware.ts'
import basketReducer from './reducers/basketReducer'
import cashlessCardReducer from './reducers/cashlessCardReducer'
import cookiesModalReducer from './reducers/cookiesModalReducer'
import kioskReducer from './reducers/kioskReducer'
import loyaltyReducer from './reducers/loyaltyReducer'
import metadataReducer from './reducers/metadataReducer'
import modalReducer from './reducers/modalReducer'
import ordersReducer from './reducers/ordersReducer'
import tableNumberReducer from './reducers/tableNumberReducer'
import themeReducer from './reducers/themeReducer'
import tokensReducer from './reducers/tokensReducer'

const persistConfig = {
    key: 'root',
    storage,
    version: 1.3,
    whitelist: ['tokens', 'basket', 'tableNumbers', 'metadata', 'loyalty', 'cashlessCard', 'orders', 'kiosk', 'theme'],
}

const rootReducer = combineReducers({
    tableNumbers: tableNumberReducer,
    metadata: metadataReducer,
    tokens: tokensReducer,
    basket: basketReducer,
    modal: modalReducer,
    cookiesModal: cookiesModalReducer,
    loyalty: loyaltyReducer,
    cashlessCard: cashlessCardReducer,
    orders: ordersReducer,
    kiosk: kioskReducer,
    theme: themeReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewareEnhancer = applyMiddleware(filterExpiredBasketsMiddleware)

const composeWithDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const composedEnhancers = composeWithDevTools(middlewareEnhancer)

export const store = createStore(persistedReducer, composedEnhancers)
export const persistor = persistStore(store)
